body {
  margin: 0;
}

body, html {
  overflow-x: hidden;
  height: 100%;
}

button {
  display: none;
}

.rec-slider-container {
  margin: 0 !important;
}

.header {
  display: flex;
  position: relative;
  border-bottom: 2px solid #ed2e38;

  @media screen and (min-width: 1024px) {
    display: flex;
  }

  .logo {
    max-width: 35%;
    margin: 1rem 0 1rem 1rem;

    @media screen and (min-width: 1024px) {
      max-width: 150px;
    }
    
    img {  
      width: 100%;
      height: auto;

      @media screen and (min-width: 1024px) {
        max-width: 9rem;
      }
    }
  }
  .menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;

    input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;
    }
    span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background: #000;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;
    }
    span:first-child {
      transform-origin: 0% 0%;
    }
    span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
    input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-10px, -19px);
      background: #000;
    }
    input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, 10px);
    }
    .menu-bg {
      background-color: #f4f0f0;
      z-index: 1;
      height: 100vh;

      ul {
        list-style: none;
        padding: 5rem 2rem 0;

        @media screen and (min-width: 1025px) {
          gap: 2rem;
          padding: 0 2rem 0;
        }

        li {
          padding: .3rem 0;
          font-size: 1.1rem;
          font-family: Verdana, Geneva, Tahoma, sans-serif;

          a {
            text-decoration: none;
            color: #000;
          }
        }
      }
      &.closed {
        position: absolute;
        visibility: hidden;
        right: -250px;
        top: 0;
        transition: all .5s;
      }
      &.open {
        position: absolute;
        visibility: visible;
        right: 0;
        top: 0;
        transition: right .5s;
      }
    }
    .mobileMenu {
      position: relative;
      z-index: 2;
    }

    @media screen and (min-width: 1025px) {
      .mobileMenu {
        display: none;
      }
      .menu-bg {
        display: flex;
        position: relative;
        height: inherit;
        background: none;
      }
      ul {
        display: flex;
        padding: 0;
  
        li {
          padding: 0 1rem;
        }
      }
    }
  }
}

.wrapper {
  .storefront {
    margin: 0 0 1rem 0;

    img {
      width: 100%;
      height: auto;
    }
  }
  .main-content {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 2rem 1rem 0;
    max-width: 1000px;
    margin: 0 auto;

    h1 {
      margin: 0 0 1rem;
      text-align: center;
      font-size: 1.4rem;
    }
    h2 {
      margin-top: 2rem;
      font-size: 1.1rem;
      text-align: center;
    }

    .contact {
      .phone {
        margin-top: 2rem;

        a {
          color: #000;
        }
      }
      .map {
        margin-top: 2rem;
        height: 25rem;

        iframe {
          width: 100%;
        }
      }
      gmp-map {
        height: 100%;
      }
    }
    .pdf {
      display: flex;
      padding: 2rem 0 1rem;

      .pdf-icon {
        width: 3rem;
        padding-right: 1rem;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .services {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 2rem;

    select {
      font-size: 1.1rem;
      border-radius: 10px;
      padding: .5rem 1rem;
      vertical-align: middle;
      color: #000;
    } 
  }

  .services-output {
    img {
      width: 100%;
      height: auto;
    }
  }
  
  .hero {
    .slideshow {
      width: 100%;
  
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  // .rec-carousel-wrapper {
  //   height: 25rem;
  // }
  // .rec-slider {
  //   top: -22rem !important;
  // }
}

.footer {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 3rem 0 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  line-height: 1.5rem;
}
